/*font:  Lexend*/
@import url('https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Lexend Exa',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  display: flex;
  flex-direction: row;
}

.copier {
  text-align: left;
  border: none;
  background: none;
}

.copier:hover {
  background: yellow;
}

a {
  color: black;
  text-decoration: none;
}